import React, { useState, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import toast from 'react-hot-toast'

import JobApplicationModal from '../components/JobApplicationModal'
import JobDetailsModal from '../components/JobDetailsModal'
import { Button } from '../../../components/Button'
import pluralize from 'pluralize'
import { FiClock } from 'react-icons/fi'
import { MdOutlineDateRange, MdOutlineSupervisorAccount } from 'react-icons/md'

import { ModalContext } from '../../../context/ModalContext'

import { getFromLocalStorage, numberCommaSeparator } from '../../../utils/util'

import ReactGA4 from '../../../lib/ReactGA4'

import {
  selectJob,
  setSuggestedJobsModal,
} from '../../../redux/feature/jobSlice'

import apiClient from '../../../helpers/apiClient'

const SuggestedJobsTab = () => {
  const dispatch = useDispatch()

  const { suggestedJobsModal } = useSelector((state) => state.jobs)

  const [isLoading, setIsLoading] = useState(true)
  const [jobs, setJobs] = useState([])
  const { setModal } = useContext(ModalContext)

  const [showAllJobs, setShowAllJobs] = useState(false)

  const userId = getFromLocalStorage('user_id')

  const handleJobApplication = (job) => {
    dispatch(selectJob(job))
    dispatch(setSuggestedJobsModal('applyJob'))
    setModal(true)
  }

  const handleViewListing = (job) => {
    let updatedJob = { ...job, is_saved: false }

    const request = {
      applicant_id: userId.toString(),
      job_id: job?.id?.toString(),
    }
    const urlParams = new URLSearchParams(request).toString()

    apiClient
      .get(`/saved-jobs?${urlParams}`)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          const isSaved = response?.data?.data[0].is_saved === 1
          updatedJob = { ...updatedJob, is_saved: isSaved }
        }
        dispatch(selectJob(updatedJob))
        const reactGA4 = new ReactGA4()
        reactGA4.sendPageView(`View Listing - ${job.title}`)

        dispatch(setSuggestedJobsModal('viewJob'))
        setModal(true)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }

  const handleSeeMore = () => {
    setShowAllJobs(true)
  }

  useEffect(() => {
    setIsLoading(true)
    apiClient
      .get('/suggested-jobs', {
        params: {
          user_id: userId,
          status: 'active',
        },
      })
      .then((res) => {
        setJobs(res?.data?.data?.jobs)
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
        setIsLoading(false)
      })
  }, [])

  return (
    <StyledSuggestedJobsTab>
      <>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            {suggestedJobsModal === 'applyJob' && <JobApplicationModal />}
            {suggestedJobsModal === 'viewJob' && <JobDetailsModal />}

            {jobs?.slice(0, showAllJobs ? jobs.length : 5).map((job, index) => (
              <div className="suggested__jobs__wrapper" key={index}>
                <div className="suggested__jobs__header">
                  <h4 className={`suggested__jobs__title ${job?.status}`}>
                    {job?.title}
                  </h4>
                </div>
                <p className="salaries">
                  PHP {numberCommaSeparator(job?.min_salary)} - PHP{' '}
                  {numberCommaSeparator(job?.max_salary)}
                </p>
                <div className="more_details">
                  <div className="details">
                    <MdOutlineSupervisorAccount className="icon" />
                    <p className="content">
                      Hiring {job?.active_vacancy}{' '}
                      {pluralize('Candidate', job?.active_vacancy)}
                    </p>
                  </div>
                  <div className="details">
                    <MdOutlineDateRange className="icon" />
                    <p className="content">
                      {job?.job_type[0].toUpperCase() + job?.job_type.slice(1)}
                    </p>
                  </div>
                  <div className="details">
                    <FiClock className="icon" />
                    <p className="content">{job?.time_shift}</p>
                  </div>
                </div>
                <div className="suggested__jobs__button__group">
                  <Button
                    radius={'none'}
                    height="3rem"
                    handleClick={() => {
                      handleJobApplication(job)
                    }}
                  >
                    Apply Now
                  </Button>
                  <Button
                    radius={'none'}
                    btnClass="secondary"
                    height="3rem"
                    handleClick={() => {
                      handleViewListing(job)
                    }}
                  >
                    View Listing
                  </Button>
                </div>
              </div>
            ))}
            <br />
            {!showAllJobs && jobs?.length > 5 && (
              <button className="button__link" onClick={handleSeeMore}>
                See More
              </button>
            )}

            {jobs?.length === 0 && (
              <EmptySuggestedJobsWrapper>
                <p>
                  You currently have no suggested jobs,
                  <br /> search for a job to apply to{' '}
                  <a
                    href="/home"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text"
                  >
                    here
                  </a>
                </p>
              </EmptySuggestedJobsWrapper>
            )}
          </>
        )}
      </>
    </StyledSuggestedJobsTab>
  )
}

export const StyledSuggestedJobsTab = styled.section`
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .suggested__jobs__wrapper {
    padding: 20px 0;
    border-bottom: 1px solid #eaeaea;

    .suggested__jobs__header {
      display: flex;
      justify-content: space-between;

      .suggested__jobs__title {
        font-weight: 600;
        font-size: 20px;
        color: #31374f;

        &.closed {
          color: #909090;
        }
      }

      .suggested__jobs__header__icon {
        font-size: 26px;
        cursor: pointer;
      }
    }

    .suggested__jobs__desc {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #31374f;
      font-size: 14px;

      &.closed {
        color: #909090;
      }
    }

    .suggested__jobs__button__group {
      display: flex;
      gap: 0.5rem;
      color: #1a1a1a;
      max-width: 300px;
      text-align: center;
      padding: 5px 0 8px;
      border-radius: 5px;
      font-size: 14px;
    }

    .inactive__listing {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 295px;
      height: 35px;
      background-color: #e4e4e4;
      padding: 1rem;

      .inactive__listing__icon {
        font-size: 24px;
        color: #4f4f4f;
      }
    }

    .salaries {
      font-size: 1rem;
      font-weight: 300;
      color: #31374f;
    }

    .more_details {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.75rem;
      margin-bottom: 0.3rem;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .details {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .icon {
          font-size: 1.25rem;
          color: #31374f;
        }

        .content {
          font-size: 0.75rem;
          font-weight: 300;
          color: #31374f;
        }
      }
    }
  }

  .button__link {
    background: none;
    color: #007bff;
    border: none;
    padding: 10px;
    font: inherit;
    text-decoration: underline;
    outline: none;
  }
`

const EmptySuggestedJobsWrapper = styled.div`
  text-align: center;

  .empty__suggested__jobs__listings__icon {
    width: 100px;
  }

  p {
    color: #31374f;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
`

export default SuggestedJobsTab
