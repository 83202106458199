import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'

import Error from '../components/Error'
import ScrollToTop from '../components/ScrollRestoration'
import { Input, PasswordInput } from '../components/Input'
import { Button } from '../components/Button'
import { baseUrl } from '../constants/constant'

import apiClient from '../helpers/apiClient'

import ReactGA4 from '../lib/ReactGA4'

import useFeatureFlag from '../hooks/useFeatureFlag'

import { PagesContext } from '../context/FormPagesContext'

import { getFromLocalStorage } from '../utils/util'

const Login = ({ type, onCustomLogin }) => {
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [isLoginCheckComplete, setIsLoginCheckComplete] = useState(false)

  const { setCvUserData } = useContext(PagesContext)

  const { activeFlag } = useFeatureFlag('FF_APPLICANT_LOGIN_LOCAL_STORAGE')

  const token = activeFlag
    ? getFromLocalStorage('token')
    : localStorage.getItem('token')

  useEffect(() => {
    if (token || isLoginCheckComplete) {
      history.push('/')
    } else {
      localStorage.removeItem('user')
      localStorage.removeItem('file')
      localStorage.removeItem('imgLink')
      localStorage.removeItem('educationData')
      localStorage.removeItem('email')
      localStorage.removeItem('professionalHeadlinesId')
      localStorage.removeItem('applicant-cv-data')
      localStorage.removeItem('applicant-data')
      localStorage.removeItem('applicant-wfh')
      localStorage.removeItem('reachPage')
      localStorage.removeItem('page')
      localStorage.removeItem('user_id')
    }
  }, [])

  useEffect(() => {
    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView('Login')
  }, [])

  /**
   * Handles the email value.
   *
   * @param {any} value - The email value.
   */
  const handleEmail = (value) => {
    setEmail(value)
  }

  /**
   * Handles the password value.
   *
   * @param {type} value - The new password value to be set.
   */
  const handlePassword = (value) => {
    setPassword(value)
  }

  /**
   * Handles the login form submission.
   *
   * @param {object} e - The event object.
   */
  const handleSubmit = (e) => {
    e.preventDefault()

    localStorage.setItem('open_subscription', true)

    // check if email passes regex check
    if (email !== '' && password !== '') {
      setIsLoading(true)

      const data = {
        email,
        password,
      }

      const setUserDetails = async (user) => {
        try {
          const { data } = await axios.post(
            `${baseUrl}/user/login/applicant`,
            user
          )
          localStorage.setItem('token', data.data.token)

          const cvData = data && data.data && data.data.cvData[0]
          const userData = data && data.data && data.data.user

          localStorage.setItem('applicant-data', JSON.stringify(userData))
          localStorage.setItem('applicant-cv-data', JSON.stringify(cvData))
          localStorage.setItem('profileImgLink', cvData.cv_image_url)

          if (userData && userData.id) {
            localStorage.setItem('user_id', userData.id)
          }

          // check if cv as been uploaded
          const cvUpload = data?.data?.cvData[0]
            ? await apiClient.get(`${data?.data?.cvData[0]?.id}/uploaded-cv`)
            : null

          const { data: workEthics } = await apiClient.get(
            `/user/${data?.data?.user?.id}/work-home`
          )

          localStorage.setItem('applicant-wfh', JSON.stringify(workEthics.resp))

          if (onCustomLogin) {
            onCustomLogin()
            setIsLoading(false)
            return
          }

          if (workEthics?.resp && workEthics?.resp?.id) {
            history.push('/home')
            type === 'modal' && window.location.reload(true)
          } else if (
            cvData?.name ||
            cvUpload?.data?.cvUpload?.resp?.length > 0
          ) {
            history.push('/welcome')
          } else {
            history.push('/onboarding')
          }

          setCvUserData(cvData)

          setIsLoginCheckComplete(true)
          setIsLoading(false)
        } catch (error) {
          setIsLoading(false)
          setError(
            error?.response?.data?.message ||
              error?.response?.data ||
              'opps, Something went wrong!'
          )
        }
      }
      setUserDetails(data)
    } else {
      setError('Please fill all fields')
    }
  }

  return (
    <>
      <ScrollToTop />
      <LoginContainer onSubmit={handleSubmit}>
        <h2 className={error ? 'newSpace' : ''}>Login</h2>
        {error && <Error error={error} />}
        <Input
          value={email}
          error={error !== ''}
          onChange={handleEmail}
          title="Email"
        />
        <PasswordInput
          error={error !== ''}
          value={password}
          onChange={handlePassword}
          title="Password"
          noValidation
        />
        <Button
          mb="1rem"
          handleClick={handleSubmit}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
        >
          {isLoading ? (
            <Loader type="ThreeDots" color="#FFF" height={32} width={32} />
          ) : (
            'Login'
          )}
        </Button>

        <Link className="registerLink" to="/signup">
          Create an account here
        </Link>

        <div className="linkContainer">
          <Link className="forgetLink" to="/forget-password">
            Forgot your password?
          </Link>
        </div>
      </LoginContainer>
    </>
  )
}

export default Login

export const LoginContainer = styled.form`
  width: 100%;

  h2 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 300;
    color: #31374f;
    margin-bottom: 1.5rem;

    &.newSpace {
      margin-bottom: 1rem;
    }
  }

  .subTitle {
    font-size: 0.75rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.5;
    max-width: 323px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .socialIcon {
    font-size: 1rem;
    margin-right: 1rem;

    &.fb {
      color: #1877f2;
    }
  }

  .registerLink {
    width: 100%;
    height: 3.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    font-weight: 700;
    color: #fff;
    border-radius: 100px;
    background-color: #28a745;
    cursor: pointer;
    outline: none;
    border: none;
    text-decoration: none;
  }

  p {
    font-size: 0.75rem;
    font-weight: 400;
    color: #31374f;
    text-align: center;

    &.mt-3 {
      margin-top: 2rem;
    }

    .iconLink {
      color: #fd2055;
      text-decoration: none;
    }
  }

  .linkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    .forgetLink {
      text-decoration: none;
      font-size: 0.75rem;
      font-weight: 400;
      color: #fd2055;
      text-align: center;
    }
  }
`
