import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'

import DatePicker from 'react-datepicker'

import { FiPlus } from 'react-icons/fi'
import toast, { Toaster } from 'react-hot-toast'
import moment from 'moment'
import Joi from 'joi'
import styled from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'

import ScrollToTop from '../../components/ScrollRestoration'
import ProfileDetails from '../../components/ProfileDetails'
import ToggleBtn from '../../components/ToggleBtn'
import { StyledPersonalInfo } from './PersonalInfo'

import { PagesContext } from '../../context/FormPagesContext'

import { levels } from '../../constants/levelOfStudy'

const EducationalBackground = () => {
  const {
    setPage,
    setReachPage,
    from,
    setFrom,
    to,
    setTo,
    educationLevel,
    setEducationLevel,
    fieldOfStudy,
    setFieldOfStudy,
    school,
    setSchool,
    currentStatus,
    setCurrentStatus,
    schoolLocation,
    setSchoolLocation,
    educationalData,
    setEducationalData,
    eduToggle,
    setEduToggle,
  } = useContext(PagesContext)

  const [show, setShow] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const topLabelRef = useRef()

  const data = {
    educationLevel,
    school,
    schoolLocation,
    fieldOfStudy,
    from,
    currentStatus,
    to,
    id: new Date(),
  }

  const validationSchema = useMemo(() => {
    if (currentStatus === 'graduated') {
      return Joi.object({
        educationLevel: Joi.string().label('Level of Eucation').required(),
        fieldOfStudy: Joi.string().label('Field of Study').required(),
        school: Joi.string().label('School').required(),
        schoolLocation: Joi.string().label('School Location'),
        currentStatus: Joi.string().label('Status').required(),
        from: Joi.date()
          .label('Date From')
          .less('now')
          .less(new Date(to))
          .message('Date From must be lesser than Date To and the current date')
          .required(),
        to: Joi.date()
          .label('Date To')
          .less('now')
          .greater(new Date(from))
          .message('Date To must be greater than Date From')
          .required(),
        id: Joi.date().not().required(),
      })
    } else {
      return Joi.object({
        educationLevel: Joi.string().label('Level of Eucation').required(),
        fieldOfStudy: Joi.string().label('Field of Study').required(),
        school: Joi.string().label('School').required(),
        schoolLocation: Joi.string().label('School Location'),
        currentStatus: Joi.string().label('Status').required(),
        from: Joi.date()
          .less('now')
          .message('Date From must not be from the future')
          .required(),
        to: Joi.date().not().required(),
        id: Joi.date().not().required(),
      })
    }
  }, [currentStatus, to, from])

  useEffect(() => {
    if (educationalData?.length > 0) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [])

  useEffect(() => {
    if (eduToggle === true) {
      setEducationalData([])
    }

    if (eduToggle === true) {
      clear()
    }
  }, [eduToggle])

  const clear = () => {
    setEducationLevel('')
    setFieldOfStudy('')
    setSchool('')
    setSchoolLocation('')
    setTo(new Date())
    setFrom(new Date())
  }

  const updatePage = () => {
    localStorage.setItem('page', 0.6)
    localStorage.setItem('reachPage', 0.6)
  }

  const handleStoreData = () => {
    const result = validationSchema.validate(data, {
      abortEarly: false,
    })

    if (!result.error && show) {
      setEducationalData((prevState) => {
        if (prevState) {
          return [...prevState, data]
        } else {
          return [data]
        }
      })
      clear()
      setFieldErrors({})
      topLabelRef.current.focus()
    } else {
      const validationErrors = result.error.details.reduce((acc, curr) => {
        acc[curr.context.key] = curr.message.replace(/"/g, '')
        return acc
      }, {})

      setFieldErrors(validationErrors)
      toast.error('Please correct the validation errors before proceeding.')
    }
  }

  const handleEdit = (id) => {
    const newData = educationalData.find((item) => item.id === id)
    const filteredData = educationalData.filter((item) => item.id !== id)

    setShow(true)

    setEducationLevel(newData.educationLevel)
    setFieldOfStudy(newData.fieldOfStudy)
    setSchool(newData.school)
    setSchoolLocation(newData.schoolLocation)
    setFrom(moment(newData.from).toDate())
    setTo(moment(newData.to).toDate())

    setEducationalData(filteredData)
  }

  const handleDelete = (id) => {
    setEducationalData(educationalData.filter((item) => item.id !== id))
  }

  const handleGoBack = () => {
    setPage(0.2)
    setReachPage(0.2)
    localStorage.setItem('page', 0.2)
    localStorage.setItem('reachPage', 0.2)
  }

  const handleNext = () => {
    const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))
    const oldMeta = cvData && cvData.meta ? JSON.parse(cvData.meta) : {}

    if (!eduToggle && show) {
      const result = validationSchema.validate(data, {
        abortEarly: false,
      })

      if (!result.error) {
        const newList = educationalData ? [...educationalData, data] : [data]
        const meta = JSON.stringify({
          ...oldMeta,
          educationalData: newList,
        })
        localStorage.setItem('educationData', JSON.stringify(newList))
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: meta,
          })
        )
        setEducationalData(newList)
        clear()
      } else {
        const validationErrors = result.error.details.reduce((acc, curr) => {
          acc[curr.context.key] = curr.message.replace(/"/g, '')
          return acc
        }, {})

        setFieldErrors(validationErrors)
        toast.error('Please correct the validation errors before proceeding.')

        return
      }
    }
    setPage(0.6)
    setReachPage(0.6)
    updatePage()
  }

  const handleAddEduBackground = () => {
    if (!show) {
      setShow(true)
      return
    }

    handleStoreData()
  }

  const handleRemove = () => {
    setShow(false)
  }

  const handleToggle = () => {
    if (!eduToggle) setFieldErrors({})
    setEduToggle(!eduToggle)
  }

  return (
    <StyledPersonalInfo>
      <ScrollToTop />
      <h2 tabIndex="-1" ref={topLabelRef}>
        Educational Background
      </h2>
      <p className="small-subtitle mb-2">
        Please provide your educational background
      </p>

      {educationalData?.map(
        (
          {
            educationLevel,
            school,
            id,
            schoolLocation,
            from,
            to,
            currentStatus,
          },
          index
        ) => (
          <ProfileDetails
            key={index}
            educationLevel={educationLevel}
            currentStatus={currentStatus}
            school={school}
            schoolLocation={schoolLocation}
            from={from}
            to={to}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            id={id}
          />
        )
      )}

      {/* toggle button */}
      {!educationalData?.length && (
        <div className="toggleBtnWrapper">
          <ToggleBtn toggle={eduToggle} setToggle={handleToggle} />
          <p className="small-subtitle">I have no educational background</p>
        </div>
      )}

      <div className="inputs">
        {show && (
          <>
            {/* Level of Education */}
            <div className="inputs">
              <label htmlFor="level">Level of Education</label>

              <select
                style={{
                  borderColor: 'educationLevel' in fieldErrors && 'red',
                }}
                onChange={(e) => setEducationLevel(e.target.value)}
                disabled={eduToggle}
                className="select"
                value={educationLevel}
                name=""
                id="level"
              >
                <option>Choose Option</option>

                {levels.map((level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                ))}
              </select>
              {'educationLevel' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.educationLevel}</sub>
              )}
            </div>

            {/* Field of Study */}
            <div className="inputs">
              <label htmlFor="fieldOfStudy">Field of Study</label>

              <input
                className={`input ${
                  'fieldOfStudy' in fieldErrors && 'has-error'
                }`}
                disabled={eduToggle}
                id="fieldOfStudy"
                type="text"
                placeholder="e.g. Agriculture, Law, Chemistry"
                onChange={(e) => setFieldOfStudy(e.target.value)}
                value={fieldOfStudy}
              />
              {'fieldOfStudy' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.fieldOfStudy}</sub>
              )}
            </div>

            {/* School */}
            <div className="inputs">
              <label htmlFor="school">School</label>

              <input
                className={`input ${'school' in fieldErrors && 'has-error'}`}
                disabled={eduToggle}
                id="school"
                type="text"
                placeholder="School"
                onChange={(e) => setSchool(e.target.value)}
                value={school}
              />
              {'school' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.school}</sub>
              )}
            </div>

            {/* School Location */}
            <div className="inputs">
              <label htmlFor="schoolLocation">School Location</label>

              <input
                className={`input ${
                  'schoolLocation' in fieldErrors && 'has-error'
                }`}
                disabled={eduToggle}
                id="school-location"
                type="text"
                placeholder="School Location"
                onChange={(e) => setSchoolLocation(e.target.value)}
                value={
                  schoolLocation.label ? schoolLocation.label : schoolLocation
                }
              />
              {'schoolLocation' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.schoolLocation}</sub>
              )}
            </div>

            {/* Current Status */}
            <div className="inputs">
              <label htmlFor="currentStatus">Current Status</label>

              <div className="radioBtns">
                <div className="radio">
                  <input
                    disabled={eduToggle}
                    className="radio-input"
                    type="radio"
                    name="status"
                    id="graduated"
                    value="graduated"
                    checked={currentStatus === 'graduated'}
                    onChange={(e) => setCurrentStatus(e.target.value)}
                  />
                  <label htmlFor="graduated" className="small-subTitle">
                    Graduated
                  </label>
                </div>
                <div className="radio">
                  <input
                    disabled={eduToggle}
                    className="radio-input"
                    type="radio"
                    name="status"
                    id="currently"
                    value="currently enrolled"
                    checked={currentStatus === 'currently enrolled'}
                    onChange={(e) => setCurrentStatus(e.target.value)}
                  />
                  <label htmlFor="currently" className="small-subTitle">
                    Currently Enrolled
                  </label>
                </div>
              </div>
            </div>

            {/* Data Grid */}
            <div className="data-grid">
              <div className="inputs">
                <label htmlFor="">Date From</label>
                <StyledDatePickerWrapper>
                  <DatePicker
                    className={'from' in fieldErrors && 'has-error'}
                    disabled={eduToggle}
                    selected={from}
                    onChange={(date) => setFrom(date)}
                    showMonthYearPicker
                    dateFormat="MMMM yyyy"
                    placeholderText="MM/YYYY"
                  />
                  {'from' in fieldErrors && (
                    <sub style={{ color: 'red' }}>{fieldErrors.from}</sub>
                  )}
                </StyledDatePickerWrapper>
              </div>
              <div className="inputs">
                <label htmlFor="">Date To</label>
                <StyledDatePickerWrapper>
                  <DatePicker
                    className={'to' in fieldErrors && 'has-error'}
                    disabled={
                      eduToggle || currentStatus === 'currently enrolled'
                    }
                    selected={
                      currentStatus === 'currently enrolled' ? undefined : to
                    }
                    onChange={(date) => setTo(date)}
                    showMonthYearPicker
                    placeholderText="MM/YYYY"
                    dateFormat="MMMM yyyy"
                  />
                  {'to' in fieldErrors && (
                    <sub style={{ color: 'red' }}>{fieldErrors.to}</sub>
                  )}
                </StyledDatePickerWrapper>
              </div>
            </div>
          </>
        )}

        <Toaster position="top-right" reverseOrder={false} />

        {!eduToggle && (
          <div className="addContainer mt">
            <button onClick={handleAddEduBackground} className="keywordBtn">
              <span className="mr">
                <FiPlus className="icon" />
              </span>
              Add another educational background
            </button>

            {educationalData?.length > 0 && show && (
              <button onClick={handleRemove} className="keywordBtn remove">
                Remove
              </button>
            )}
          </div>
        )}
      </div>

      <div className="buttonContainer">
        <button className="btn" onClick={handleGoBack}>
          Go Back
        </button>
        <button className="btn filled" onClick={handleNext}>
          Next Step
        </button>
      </div>
    </StyledPersonalInfo>
  )
}

const StyledDatePickerWrapper = styled.div`
  .has-error {
    border-color: red !important;
  }
`

export default EducationalBackground
