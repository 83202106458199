import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'

import PhoneInput from 'react-phone-input-2'
import toast, { Toaster } from 'react-hot-toast'
import { FiPlus } from 'react-icons/fi'
import Joi from 'joi'

import 'react-phone-input-2/lib/style.css'

import ScrollToTop from '../../components/ScrollRestoration'
import CharacterRefDetails from '../../components/CharacterRefDetails'
import ToggleBtn from '../../components/ToggleBtn'
import { StyledPersonalInfo } from './PersonalInfo'

import { PagesContext } from '../../context/FormPagesContext'

const CharacterRef = () => {
  const {
    setPage,
    setReachPage,
    characterReferences,
    setCharacterReferences,
    crName,
    setCrName,
    crEmail,
    setCrEmail,
    crPhone,
    setCrPhone,
    toggleNoCharRef,
    setToggleNoCharRef,
  } = useContext(PagesContext)

  const [show, setShow] = useState(true)
  const [fieldErrors, setFieldErrors] = useState({})

  const topLabelRef = useRef()

  const validationSchema = useMemo(() => {
    return Joi.object({
      crName: Joi.string().label('Full Name').required(),
      crEmail: Joi.string()
        .label('Email')
        .email({ tlds: { allow: false } })
        .required(),
      crPhone: Joi.string().label('Contact Number').required(),
    })
  }, [])

  useEffect(() => {
    if (characterReferences?.length > 0) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [])

  useEffect(() => {
    const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))
    localStorage.setItem(
      'applicant-cv-data',
      JSON.stringify({
        ...cvData,
        character_reference: JSON.stringify(characterReferences),
      })
    )
  }, [characterReferences])

  const clear = () => {
    setCrName('')
    setCrEmail('')
    setCrPhone('')
  }

  const displayErrors = (result) => {
    const validationErrors = result.error.details.reduce((acc, curr) => {
      acc[curr.context.key] = curr.message.replace(/"/g, '')
      return acc
    }, {})

    setFieldErrors(validationErrors)
    toast.error('Please correct the validation errors before proceeding.')
  }

  const addNewCharacterRef = () => {
    const formData = {
      crName,
      crEmail,
      crPhone,
    }
    const result = validationSchema.validate(formData, {
      abortEarly: false,
    })

    if (!result.error && show) {
      setCharacterReferences([
        ...characterReferences,
        {
          id: new Date(),
          crName: crName,
          crEmail: crEmail,
          crPhone: crPhone,
        },
      ])
      clear()
      setFieldErrors({})
      topLabelRef.current.focus()
    } else {
      displayErrors(result)
    }
  }

  const handleEdit = (id) => {
    const item = characterReferences.filter((x) => x.id === id)[0]
    setCrName(item.crName)
    setCrEmail(item.crEmail)
    setCrPhone(item.crPhone)

    setCharacterReferences(characterReferences.filter((x) => x.id !== id))
  }

  const handleDelete = (id) => {
    setCharacterReferences(characterReferences.filter((x) => x.id !== id))
    const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))
    localStorage.setItem(
      'applicant-cv-data',
      JSON.stringify({
        ...cvData,
        character_reference: JSON.stringify(characterReferences),
      })
    )
  }

  const handleAddCharRef = () => {
    if (!show) {
      setShow(true)
      return
    }

    addNewCharacterRef()
  }

  const handleRemoveCharRef = () => {
    setFieldErrors({})
    setShow(false)
    clear()
  }

  const goToPage = (pageNum) => {
    setPage(pageNum)
    setReachPage(pageNum)
    localStorage.setItem('page', pageNum)
    localStorage.setItem('reachPage', pageNum)
  }

  const handleGoBack = () => {
    goToPage(0.8)
  }

  const handleNext = () => {
    const formData = {
      crName,
      crEmail,
      crPhone,
    }
    const result = validationSchema.validate(formData, {
      abortEarly: false,
    })

    if (toggleNoCharRef) {
      goToPage(1.2)
    } else {
      if (!result.error) {
        setCharacterReferences((prev) => [
          ...prev,
          {
            id: new Date(),
            crName: crName,
            crEmail: crEmail,
            crPhone: crPhone,
          },
        ])
        clear()
        goToPage(1.2)
      } else {
        displayErrors(result)
      }
    }
  }

  return (
    <StyledPersonalInfo>
      <ScrollToTop />

      <h2 tabIndex="-1" ref={topLabelRef}>
        Character References
      </h2>
      <p className="small-subtitle">Add your character references.</p>

      {characterReferences?.map((c) => (
        <CharacterRefDetails
          key={c.id}
          crName={c.crName}
          crEmail={c.crEmail}
          crPhone={c.crPhone}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          id={c.id}
        />
      ))}
      {/* toggle button */}
      {!characterReferences?.length && (
        <div className="toggleBtnWrapper">
          <ToggleBtn toggle={toggleNoCharRef} setToggle={setToggleNoCharRef} />
          <p className="small-subtitle">I have no character references</p>
        </div>
      )}

      {show && (
        <>
          <div className="inputs">
            <label htmlFor="jobTitle">Full Name</label>
            <input
              className={`input ${'crName' in fieldErrors && 'has-error'}`}
              id="name"
              type="text"
              placeholder="Full Name"
              readOnly={toggleNoCharRef}
              onChange={(e) => setCrName(e.target.value)}
              value={crName}
            />
            {'crName' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.crName}</sub>
            )}
          </div>
          <div className="inputs">
            <label htmlFor="jobTitle">Email</label>
            <input
              className={`input ${'crEmail' in fieldErrors && 'has-error'}`}
              id="email"
              type="text"
              placeholder="Email"
              readOnly={toggleNoCharRef}
              onChange={(e) => setCrEmail(e.target.value)}
              value={crEmail}
            />
            {'crEmail' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.crEmail}</sub>
            )}
          </div>
          <div className="inputs">
            <label htmlFor="firstName">
              Contact Number<span className="required">*</span>
            </label>
            <PhoneInput
              inputStyle={{ borderColor: 'crPhone' in fieldErrors && 'red' }}
              country={'ph'}
              disabled={toggleNoCharRef}
              value={crPhone}
              onChange={(phone) => setCrPhone(phone)}
            />
            {'crPhone' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.crPhone}</sub>
            )}
          </div>
        </>
      )}

      {!toggleNoCharRef && (
        <div className="addContainer mt">
          <button onClick={handleAddCharRef} className="keywordBtn">
            <span className="mr">
              <FiPlus className="icon" />
            </span>
            Add another character reference
          </button>
          {characterReferences?.length > 0 && show && (
            <button onClick={handleRemoveCharRef} className="keywordBtn remove">
              Remove
            </button>
          )}
        </div>
      )}

      <Toaster position="top-right" reverseOrder={false} />

      <div className="buttonContainer">
        <button className="btn" onClick={handleGoBack}>
          Go Back
        </button>
        <button className="btn filled" onClick={handleNext}>
          Next Step
        </button>
      </div>
    </StyledPersonalInfo>
  )
}

export default CharacterRef
