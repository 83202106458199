import React, { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { IoClose } from 'react-icons/io5'

import { ModalContext } from '../../../context/ModalContext'
import { addFilter, resetFilters } from '../../../redux/feature/jobSlice'

import MultiRangeSlider from '../../../components/controls/MultiRangeSlider'

import { nFormatter } from '../../../utils/nformatter'

const MobileFormFilter = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const { setMobileJobFilterForm } = useContext(ModalContext)
    const jobFilters = useSelector((state) => state.jobs.filters)

    const [range, setRange] = useState([1000, 200000])
    const [employmentType, setEmploymentType] = useState('')
    const [timeShift, setTimeShift] = useState('')
    const [datePosted, setDatePosted] = useState('')

    useEffect(() => {
        jobFilters?.salary_basis && setRange(jobFilters.salary_basis)
        jobFilters?.job_type && setEmploymentType(jobFilters.job_type)
        jobFilters?.time_shift && setTimeShift(jobFilters.time_shift)
        jobFilters?.date_posted && setDatePosted(jobFilters.date_posted)
    }, [])

    function addJobFilter(key, value) {
        value && dispatch(addFilter({ [key]: value }))
    }
    function handleCloseFilters() {
        setMobileJobFilterForm(false)
    }
    function handleApplyFilters() {
        addJobFilter('salary_basis', range)
        addJobFilter('job_type', employmentType)
        addJobFilter('time_shift', timeShift)
        addJobFilter('date_posted', datePosted)
        setMobileJobFilterForm(false)
    }
    function handleResetFilters() {
        dispatch(resetFilters())
        setMobileJobFilterForm(false)
        history.push(`/home`)
    }

    return (
        <StyledMobileFormFilter>
            <div className='header'>
                <h4 className='header-title'>Search Filters</h4>
                <IoClose className='close-icon' onClick={handleCloseFilters} />
            </div>
            <div className='filter-item'>
                <h4 className="filter-item__title">Payment</h4>
                <h5 className="slider-item__title">
                    ₱ {nFormatter(range[0])} <span> to </span> ₱ {nFormatter(range[1])} /
                    Month
                </h5>
                <MultiRangeSlider
                    defaultValue={[1000, 200000]}
                    min={0}
                    max={200000}
                    value={range}
                    onChange={(value) => setRange(value)}
                />
            </div>
            <div className='filter-item'>
                <h4 className="filter-item__title">Career Type</h4>
                <select
                    value={employmentType}
                    onChange={(e) => setEmploymentType(e.target.value)}
                    className="filter-item__select"
                >
                    <option>All Career Types</option>
                    <option value="Full-time">Full-time</option>
                    <option value="Part-time">Part-time</option>
                </select>
            </div>
            <div className='filter-item'>
                <h4 className="filter-item__title">Shift Schedule - PH Time</h4>
                <select
                    value={timeShift}
                    onChange={(e) => setTimeShift(e.target.value)}
                    className="filter-item__select"
                >
                    <option value="">All Shifts</option>
                    <option value="Morning Shift - PH TIME">Morning Shift - PH TIME</option>
                    <option value="Afternoon Shift - PH TIME">Afternoon Shift - PH TIME</option>
                    <option value="Night Shift - PH TIME">Night Shift - PH TIME</option>
                </select>
            </div>
            <div className='filter-item'>
                <h4 className="filter-item__title">Date Posted</h4>
                <select
                    value={datePosted}
                    onChange={(e) => setDatePosted(e.target.value)}
                    className="filter-item__select"
                >
                    <option value="">Any Time</option>
                    <option value="Last 24 Hours">Last 24 Hours</option>
                    <option value="Last 3 Days">Last 3 Days</option>
                    <option value="Last Week">Last Week</option>
                    <option value="Last Month">Last Month</option>
                </select>
            </div>
            <div className='filter-footer'>
                <button className="button reset" onClick={handleResetFilters}>
                    Reset
                </button>
                <button className="button apply" onClick={handleApplyFilters}>
                    Apply
                </button>
            </div>
        </StyledMobileFormFilter>
    )
}

export default MobileFormFilter;

const StyledMobileFormFilter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #eeeef6;
    padding: 1rem;
    box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
    position: fixed;
    top: 0%;
    z-index: 101;
    gap: 0.5rem;
    overflow: hidden;

    .header {
        display: flex;
        justify-content: space-between;

        .header-title {
            font-size: 1rem;
            font-weight: 500;
            color: #31374f;
            text-align: left;
            padding: 0.5rem;
        }

        .close-icon {
            font-size: 1.5rem;
            color: #31374F;
            cursor: pointer;
          }
    }
    
    .filter-item {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;

        .filter-item__title {
            font-size: 1rem;
            font-weight: 300;
            color: #31374f;
            text-align: left;
            padding: 0.5rem;
          }
    
        .slider-item__title {
            font-size: 1.5rem;
            font-weight: 300;
            color: #31374f;
            text-align: center;
        }
    
        .filter-item__select {
            width: 100%;
            height: 3rem;
            margin-top: 1rem;
            border: 1px solid #dfe2ec;
            padding: 0 1rem;
            color: #31374f;
            font-size: 1rem;
            font-weight: 300;
          }
    }

    .filter-footer {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 1rem;
        margin-top: 1rem;

        .button {
            border: none;
            outline: none;
            
            flex-grow: 2;
            color: #fff;
            padding: 0.75rem 1.5rem;
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
      
            &.reset {
                background: #FFFFFF;
                border: 1px solid #858AA0;
                color: #31374F;
            }
      
            &.apply {
            background: #ff5880;
              color: #FFFFFF
            }
          }
    }
`