import React, { useContext, useEffect, useRef } from 'react'

import { useDrop } from 'react-dnd'
import toast from 'react-hot-toast'
import { FiPlus } from 'react-icons/fi'
import axios from 'axios'

import DraggableItem from './DraggableItem'
import KeywordSearcher, { ClickableItem } from './KeywordSearcher'

import { baseUrl } from '../constants/constant'

import { PagesContext } from '../context/FormPagesContext'

import { ItemTypes } from '../utils/items'

const HeadLinesForm = ({
  headlines,
  noClick,
  className = '',
  errorMessage = '',
}) => {
  const {
    items,
    userDetails,
    setUserDetails,
    markAsMoved,
    setItems,
    check,
    setCheck,
    setSummaryToggle,
    setProfessionalHeadlinesId,
  } = useContext(PagesContext)

  const btnRef = useRef(null)

  const getProfessionalHeadLines = () => {
    axios
      .get(`${baseUrl}/professional-headlines?withSkills=true`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const data = res.data.resp
        const existingHeadlineIds = headlines.map((headline) => headline.id)

        let mappedHeadlines = data
          .filter((headline) => !existingHeadlineIds.includes(headline.id))
          .map((item) => ({
            id: item.id,
            item: item.professional_headline,
            status: 'current',
          }))

        mappedHeadlines = [...headlines, ...mappedHeadlines]
        setItems(mappedHeadlines)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getProfessionalHeadLines()
    if (headlines !== null) {
      const mainItem = items.filter((item) =>
        headlines.some((headline) => headline.item === item.item)
      )

      const newItems = []
      mainItem.forEach((item) => {
        item.status = 'moved'
        newItems.push(item)
      })

      headlines.forEach((headline) => {
        setProfessionalHeadlinesId((prevState) => {
          if (prevState.includes(headline.id)) {
            localStorage.setItem(
              'professionalHeadlinesId',
              JSON.stringify(prevState)
            )
            return prevState
          } else {
            localStorage.setItem(
              'professionalHeadlinesId',
              JSON.stringify([...prevState, headline.id])
            )
            return [...prevState, headline.id]
          }
        })
      })
    }
  }, [])

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()

      const filteredList = items.filter((item) => item.status === 'moved')

      if (didDrop || filteredList.length >= 3) {
        toast.error('Professional Headline should not be more than 3')

        return
      }
      markAsMoved(item.id)
      setProfessionalHeadlinesId((prevState) => {
        if (prevState.includes(item.id)) {
          localStorage.setItem(
            'professionalHeadlinesId',
            JSON.stringify(prevState)
          )
          return prevState
        } else {
          localStorage.setItem(
            'professionalHeadlinesId',
            JSON.stringify([...prevState, item.id])
          )
          return [...prevState, item.id]
        }
      })

      const newItems = items.filter((item) => item.status === 'moved')

      const newHeadlines = []

      newItems.forEach((item) => {
        newHeadlines.push(item)
      })

      setUserDetails({ ...userDetails, professionalHeadlines: newHeadlines })
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  })

  const handleDelete = (id) => {
    const filteredList = items.filter((headline) => headline.id !== id)
    const newHeadline = items.filter((headline) => headline.id === id)

    newHeadline[0].status = 'current'

    setItems([...newHeadline, ...filteredList])
    setUserDetails((prevState) => ({
      ...prevState,
      professionalHeadlines: items.filter(
        (headline) => headline.status === 'moved'
      ),
    }))

    setProfessionalHeadlinesId((prevState) => {
      const updatedHeadlines = prevState.filter((headline) => headline !== id)
      localStorage.setItem(
        'professionalHeadlinesId',
        JSON.stringify(updatedHeadlines)
      )
      return updatedHeadlines
    })
  }

  return (
    <div className="inputs">
      <label htmlFor="firstName">Professional Headline</label>
      <div className="moreInfo">
        <p>Choose up to 3 keywords to describe yourself professionally</p>
        {!noClick && (
          <button
            ref={btnRef}
            onClick={() => {
              setCheck(!check)
              setSummaryToggle(false)
            }}
            className="keywordBtn"
          >
            Professional Keywords
            <span>
              <FiPlus className="icon" />
            </span>
          </button>
        )}
      </div>
      <div
        className="inputWrapper flex dropzones"
        ref={drop}
        style={{
          background: isOver ? '#f5f5f5' : '#fff',
          border: isOver ? '1px solid #ccc' : '',
          flexWrap: 'wrap',
          borderColor: className === 'has-error' ? 'red' : '#ccc',
        }}
      >
        {items
          .filter((item) => item.status === 'moved')
          .map((headline, index) => (
            <>
              <DraggableItem
                key={index}
                index={index}
                id={headline.id}
                handleDelete={handleDelete}
                item={headline}
              />
              <ClickableItem
                noClose={false}
                key={headline.item.id}
                item={headline}
                id={headline.item.id}
              />
            </>
          ))}
      </div>
      {className === 'has-error' && (
        <sub style={{ color: 'red', margin: 0 }}>{errorMessage}</sub>
      )}
      {check && (
        <KeywordSearcher
          headlines={headlines}
          setCheck={setCheck}
          items={items}
        />
      )}
    </div>
  )
}

export default HeadLinesForm
