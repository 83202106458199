import React, { useEffect, useState } from 'react'

import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import Loader from 'react-loader-spinner'

import { LoginContainer } from './Login'
import { Input, PasswordInput } from '../components/Input'
import { Button } from '../components/Button'
import Error from '../components/Error'
import ScrollToTop from '../components/ScrollRestoration'

import AuthLayouts from '../layouts/AuthLayouts'

import ReactGA4 from '../lib/ReactGA4'

import { baseUrl } from '../constants/constant'

const Register = () => {
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleEmail = (value) => {
    setEmail(value)
  }

  const handlePassword = (value) => {
    setPassword(value)
  }

  useEffect(() => {
    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView('Register')
  }, [])

  useEffect(() => {
    if (localStorage.getItem('token')) {
      history.push('/')
    }
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    // check if email and password are not empty
    if (email !== '' && password !== '') {
      if (
        password.length > 7 &&
        password.match('^(?=.*[0-9])') &&
        password.match('^(?=.*[0-9])') &&
        password.match('^(?=.*[a-z])(?=.*[A-Z])')
      ) {
        setIsLoading(true)

        const data = {
          email,
          password,
        }

        axios
          .post(`${baseUrl}/user/registration`, data)
          .then((res) => {
            localStorage.setItem('email', email)
            history.push('/verification')
            setIsLoading(false)
          })
          .catch((error) => {
            setError('oops, something is wrong try again')

            if (error.response) {
              if (error.response.data.message !== undefined) {
                setError(error.response.data.message)
              } else {
                setError(error.response.data)
              }
            }
            setIsLoading(false)
          })
      } else {
        setError(
          'password must be at least 8 characters long and contain at least one number, one uppercase letter and one lowercase letter'
        )
      }
    } else {
      setError('Please enter email and password')
    }
  }

  return (
    <AuthLayouts>
      <ScrollToTop />
      <LoginContainer>
        <h2>Create Account</h2>
        {error && <Error error={error} />}
        <Input
          value={email}
          error={error !== ''}
          onChange={handleEmail}
          title="Email"
        />
        <PasswordInput
          error={error !== ''}
          value={password}
          onChange={handlePassword}
          title="Password"
        />
        <Button mb="1rem" handleClick={handleSubmit}>
          {isLoading ? (
            <Loader type="ThreeDots" color="#FFF" height={32} width={32} />
          ) : (
            'Sign Up'
          )}
        </Button>

        <p>
          Already have an account?{' '}
          <Link className="iconLink" to="/login">
            Log in Here
          </Link>
        </p>
      </LoginContainer>
    </AuthLayouts>
  )
}

export default Register
