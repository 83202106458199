import React, { useContext } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import pluralize from 'pluralize'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FiCheckCircle, FiClock } from 'react-icons/fi'
import { MdOutlineDateRange, MdOutlineSupervisorAccount } from 'react-icons/md'

import { ModalContext } from '../../../../src/context/ModalContext'

import { updateActiveJob } from '../../../redux/feature/jobSlice'

import apiClient from '../../../helpers/apiClient'

import ReactGA4 from '../../../lib/ReactGA4'

import { numberCommaSeparator, getFromLocalStorage } from '../../../utils/util'

const JobCard = ({ job }) => {
  const dispatch = useDispatch()

  const activeJob = useSelector((state) => state.jobs.activeJob)

  const { setMobileJobFilterForm } = useContext(ModalContext)

  const cvDetails = getFromLocalStorage('applicant-cv-data')
  const userId = getFromLocalStorage('user_id')

  function selectJob() {
    const updatedJob = { ...job }
    if (userId) {
      const request = {
        applicant_id: cvDetails?.id?.toString(),
        job_id: job?.id?.toString(),
        is_saved: true,
      }
      const urlParams = new URLSearchParams(request).toString()

      apiClient
        .get(`/saved-jobs?${urlParams}`)
        .then((response) => {
          const newUpdatedJob = { ...updatedJob }
          if (response?.data?.data?.length > 0) {
            newUpdatedJob.is_saved = true
          } else {
            newUpdatedJob.is_saved = false
          }
          dispatch(updateActiveJob(newUpdatedJob))
        })
        .catch((error) => toast.error(error?.response?.data?.message))
    }

    const reactGA4 = new ReactGA4()
    reactGA4.sendPageView(`${updatedJob.title}`)

    dispatch(updateActiveJob(updatedJob))
    setMobileJobFilterForm(false)
  }

  return (
    <StyledJobCard
      onClick={selectJob}
      className={activeJob?.id === job?.id && 'active'}
    >
      <div className="menu">
        <BsThreeDotsVertical className="icons" />
      </div>
      <h4 className="title">{job?.title || 'No Title'}</h4>
      <p className="salaries">
        PHP {numberCommaSeparator(job?.min_salary)} - PHP{' '}
        {numberCommaSeparator(job?.max_salary)}
      </p>

      <div className="more_details">
        <div className="details">
          <MdOutlineSupervisorAccount className="icon" />
          <p className="content">
            Hiring {job?.active_vacancy}{' '}
            {pluralize('Candidate', job?.active_vacancy)}
          </p>
        </div>
        <div className="details">
          <MdOutlineDateRange className="icon" />
          <p className="content">
            {job?.job_type[0].toUpperCase() + job?.job_type.slice(1)}
          </p>
        </div>
        <div className="details">
          <FiClock className="icon" />
          <p className="content">{job?.time_shift}</p>
        </div>
      </div>
      {
        job?.skills_match &&
        <div className='matched_skills_highlight'>
          <FiCheckCircle className="icon" />
          <p className="content">Your skills match this job</p>
        </div>
      }
    </StyledJobCard>
  )
}

export default JobCard

const StyledJobCard = styled.div`
  width: 100%;
  padding: 1.4rem 1.25rem;
  border: 2px solid #b7bbc7;
  border-radius: 3px;
  background: #fff;
  position: relative;

  &.active,
  &:hover {
    border: 1px solid #1877f2;
  }

  .menu {
    position: absolute;
    top: 0.75rem;
    right: 0.25rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .icons {
      font-size: 1rem;
      color: #31374f;
    }

    &:hover {
      cursor: pointer;
      background: #f2f3f7;
    }
  }

  .title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #31374f;
    margin-bottom: 0.75rem;
  }

  .salaries {
    font-size: 1rem;
    font-weight: 300;
    color: #31374f;
  }

  .more_details {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.75rem;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .details {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .icon {
        font-size: 1.25rem;
        color: #31374f;
      }

      .content {
        font-size: 0.75rem;
        font-weight: 300;
        color: #31374f;
      }
    }
  }

  .matched_skills_highlight {
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    color:#28a745;
    margin-top: 0.75rem;
    gap: 0.5rem;

    .icon {
      font-size: 1rem;
      color:#28a745;
    }
  }
`
