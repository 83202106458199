// import React, { useState, useEffect } from "react";
import React, { useState } from 'react'
import styled from 'styled-components'

import 'react-circular-progressbar/dist/styles.css'

import { BiLock, BiUser, BiBell, BiChevronRight } from 'react-icons/bi'

const Sidebar = ({ menuItems, onItemClick }) => {
  const [activeItem, setActiveItem] = useState(menuItems[0].label)

  const handleMenuItemClick = (menuItem) => {
    setActiveItem(menuItem)
  }

  return (
    <StyledSidebar>
      <div className="side-menu">
        <h2>Settings</h2>
        <div>
          {menuItems.map((item, index) => {
            let icon
            switch (item.label) {
              case 'Personal Information':
                icon = <BiUser className="icon" />
                break
              case 'Password':
                icon = <BiLock className="icon" />
                break
              case 'Notification':
                icon = <BiBell className="icon" />
                break
            }

            return (
              <div key={index}>
                <ul>
                  <li
                    key={item.label}
                    onClick={() => handleMenuItemClick(item.label)}
                    className={activeItem === item.label ? 'active' : ''}
                  >
                    <button id="tab_1" onClick={() => onItemClick(item)}>
                      {icon}
                      {item.label}
                      <BiChevronRight className="iconRight" />
                    </button>
                  </li>
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    </StyledSidebar>
  )
}

export default Sidebar

export const StyledSidebar = styled.aside`
  width: 280px;
  position: sticky;
  top: 8rem;

  .iconRight {
    display: none;
  }

  .side-menu {
    width: 280px;
  }

  @media (max-width: 768px) {
    width: 100%;

    .side-menu {
      width: 100%;
    }

    h2 {
      text-align: left !important;
    }
    .iconRight {
      float: right;
      font-size: 20px;
      margin-right: 10px;
      color: #31374f;
      display: block;
    }
  }

  ul {
    list-style: none;
  }

  li button {
    border: none;
    background-color: #fff;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 300;
    -webkit-letter-spacing: 5%;
    -moz-letter-spacing: 5%;
    -ms-letter-spacing: 5%;
    letter-spacing: 5%;
    color: #31374f;
    width: 100%;
    text-align: left;
  }

  li.active {
    background-color: #f1f1f1;
  }
  li.active button {
    background-color: #f1f1f1;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 5%;
    color: #31374f;
    margin-bottom: 20px;
    margin-left: 20px;
    text-align: left;
  }

  .icon {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    top: 5rem;
  }

  @media (max-width: 768px) {
    background: #fff;
    max-width: 100vw;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 3;

    .in {
      width: 100%;
      max-width: 120px;
    }
  }

  .sidebar-content {
    margin-top: 2rem;

    @media (max-width: 768px) {
      display: flex;
      align-items: flex-start;
    }
  }
`

export const StyledProgress = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  background: none;
  outline: none;
  border: none;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .circle-dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: #e9ebf2;
    position: relative;
    margin-bottom: 2.5rem;
    cursor: pointer;

    @media (max-width: 768px) {
      margin-bottom: 0.7rem;
    }

    &:after {
      content: '';
      width: 1px;
      height: 2.5rem;
      background: #e9ebf2;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      display: ${(p) => (p.last ? 'none' : 'block')};

      @media (max-width: 768px) {
        display: none;
      }
    }

    &.active {
      background: #ff5880;

      &:after {
        background: #ff5880;
      }
    }

    &.postActive {
      border: 1px solid #ff5880;
      background: #fff;

      &:after {
        background: #ff5880;
      }
    }
  }

  p {
    font-size: 0.75rem;
    font-weight: 300;
    margin-left: 2rem;

    @media (max-width: 768px) {
      text-align: center;
      margin-left: 0;
      padding: 0.5rem;
    }
  }
`
