import React, { useState, useEffect } from 'react'
import 'react-phone-input-2/lib/style.css'
import toast, { Toaster } from 'react-hot-toast'
import ScrollToTop from '../../components/ScrollRestoration'
import styled from 'styled-components'
import apiClient from '../../helpers/apiClient'

const SettingsThree = () => {
  const [checkboxList, setCheckboxList] = useState([])

  const userId = localStorage.getItem('user_id')

  useEffect(() => {
    apiClient
      .get(`/notifications/getNotificationSettings/${userId}`)
      .then((res) => {
        const dataArray = Object.values(res.data)
        const notificationSettings = dataArray.map((notification) => ({
          ...notification,
          checked: notification.checked != null ? notification.checked : true,
          user_id: notification.user_id != null ? notification.user_id : userId,
        }))
        setCheckboxList(notificationSettings)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxList.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    )

    const chosenCheckbox = updatedCheckboxes.filter(function (x) {
      return x.id === id
    })

    const data = {
      user_id: userId,
      checked: chosenCheckbox[0].checked,
      notification_settings_id: id,
    }

    apiClient
      .post(`/notifications/updateNotificationSettingsApplicant`, data)
      .then((res) => {
        toast.success('Notification Applicant Settings Successfully Changed!')
      })
      .catch((err) => {
        console.log(err)
      })

    setCheckboxList(updatedCheckboxes)
  }

  return (
    <StyledSettingsThree>
      <ScrollToTop />
      <h2>Notification</h2>
      <br />
      <div>
        <div>
          {checkboxList.map((checkbox) => (
            <div key={checkbox.id}>
              <div>
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    name={checkbox.name}
                    id={checkbox.id}
                    checked={checkbox.checked}
                    onChange={() => handleCheckboxChange(checkbox.id)}
                  />
                  <label htmlFor={checkbox.id}></label>
                </div>
                <span>{checkbox.name}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br />
      <br />
      <br />
      <Toaster position="top-right" reverseOrder={false} />
    </StyledSettingsThree>
  )
}

export default SettingsThree

export const StyledSettingsThree = styled.div`
  width: 100%;
  padding: 0 15px;
  padding-left: 30px;

  h2 {
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 5%;
    color: #31374f;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  span {
    font-weight: 300;
    letter-spacing: 5%;
    color: #31374f;
    margin-bottom: 20px;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-bottom: 10px;
  }

  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle-switch label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 24px;
    background-color: #ccc; /* Background color when in the "OFF" state */
    border-radius: 34px;
    cursor: pointer;
  }

  .toggle-switch label:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background-color: #fff; /* Circle color when in the "OFF" state */
    border-radius: 50%;
    transition: 0.2s;
  }

  .toggle-switch input:checked + label {
    background-color: #ff5880; /* Background color when in the "ON" state */
  }

  .toggle-switch input:checked + label:before {
    transform: translateX(
      26px
    ); /* Move the circle to the right when in the "ON" state */

  
  .toggle-switch input:checked + label:before {
    transform: translateX(26px); /* Move the circle to the right when in the "ON" state */
  }
  
`
