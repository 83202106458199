import React, { useContext, useState, useEffect, useMemo, useRef } from 'react'

import { FiPlus } from 'react-icons/fi'
import DatePicker from 'react-datepicker'
import { MdLightbulbOutline } from 'react-icons/md'
import toast, { Toaster } from 'react-hot-toast'
import moment from 'moment'
import Joi from 'joi'
import styled from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'

import ScrollToTop from '../../components/ScrollRestoration'
import ToggleBtn from '../../components/ToggleBtn'
import ExperiencePopup from '../../components/ExperiencePopup'
import ExperienceDetails from '../../components/ExperienceDetails'
import RichTextbox from '../../components/RichTextbox'
import { StyledPersonalInfo } from './PersonalInfo'

import { PagesContext } from '../../context/FormPagesContext'

import { industries } from '../../constants/industry'

const JobExperience = () => {
  const {
    setPage,
    setReachPage,
    experienceFrom,
    setExperienceFrom,
    experienceTo,
    setExperienceTo,
    jobTitle,
    setJobTitle,
    companyName,
    setCompanyName,
    companyLocation,
    setCompanyLocation,
    industry,
    setIndustry,
    workType,
    setWorkType,
    experienceCurrentStatus,
    setExperienceCurrentStatus,
    jobDescriptionHtml,
    setJobDescriptionHtml,
    experienceState,
    setExperienceState,
    educationalData,
    toggle,
    setToggle,
  } = useContext(PagesContext)

  const [show, setShow] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const data = {
    jobTitle,
    companyName,
    companyLocation: companyLocation,
    industry,
    workType,
    experienceCurrentStatus,
    jobDescriptionHtml,
    experienceFrom,
    experienceTo,
    id: new Date(),
  }

  const topLabelRef = useRef()

  const validationSchema = useMemo(() => {
    let schema
    if (experienceCurrentStatus === 'Currently employed') {
      schema = Joi.object({
        jobTitle: Joi.string().label('Job Title').required(),
        companyName: Joi.string().label('Company').required(),
        companyLocation: Joi.string().label('Company Location').required(),
        industry: Joi.string().label('Industry').required(),
        workType: Joi.string().label('Work Type').required(),
        experienceCurrentStatus: Joi.string()
          .label('Current Status')
          .required(),
        jobDescriptionHtml: Joi.string()
          .label('Job Description')
          .required()
          .custom((value, helpers) => {
            // Check if the value is empty or contains only an empty paragraph
            if (value.trim() === '<p><br></p>') {
              return helpers.error('any.invalid', {
                label: 'Job Description',
              })
            }
            return value
          })
          .messages({
            'any.required': '"Job Description" is required',
            'any.invalid': '"Job Description" is not allowed to be empty',
          }),
        experienceFrom: Joi.date()
          .label('Date From')
          .less('now')
          .message('Date From must be lesser than Date To and the current date')
          .required(),
        experienceTo: Joi.not().required(),
        id: Joi.date().not().required(),
      })
    } else {
      schema = Joi.object({
        jobTitle: Joi.string().label('Job Title').required(),
        companyName: Joi.string().label('Company').required(),
        companyLocation: Joi.string().label('Company Location').required(),
        industry: Joi.string().label('Industry').required(),
        workType: Joi.string().label('Work Type').required(),
        experienceCurrentStatus: Joi.string()
          .label('Current Status')
          .required(),
        jobDescriptionHtml: Joi.string()
          .label('Job Description')
          .required()
          .custom((value, helpers) => {
            // Check if the value is empty or contains only an empty paragraph
            if (value.trim() === '<p><br></p>') {
              return helpers.error('any.invalid', {
                label: 'Job Description',
              })
            }
            return value
          })
          .messages({
            'any.required': '"Job Description" is required',
            'any.invalid': '"Job Description" is not allowed to be empty',
          }),
        experienceFrom: Joi.date()
          .label('Date From')
          .less('now')
          .less(new Date(experienceTo))
          .message('Date From must be lesser than Date To and the current date')
          .required(),
        experienceTo: Joi.date()
          .label('Date To')
          .less('now')
          .greater(new Date(experienceFrom))
          .message('Date To must be greater than Date From')
          .required(),
        id: Joi.date().not().required(),
      })
    }
    return schema
  }, [experienceTo, experienceFrom, experienceCurrentStatus])

  const clear = () => {
    setJobTitle('')
    setCompanyName('')
    setCompanyLocation('')
    setIndustry('')
    setWorkType('')
    setExperienceCurrentStatus('')
    setJobDescriptionHtml('')
    setExperienceTo('')
    setExperienceFrom('')
  }

  useEffect(() => {
    if (experienceState?.length > 0) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [])

  const handleStoreData = () => {
    const result = validationSchema.validate(data, { abortEarly: false })

    if (!result.error) {
      setExperienceState((prevState) => {
        if (prevState) {
          return [...prevState, data]
        } else {
          return [data]
        }
      })
      clear()
      setFieldErrors({})
    } else {
      const validationErrors = result.error.details.reduce((acc, curr) => {
        acc[curr.context.key] = curr.message.replace(/"/g, '')
        return acc
      }, {})

      setFieldErrors(validationErrors)
      toast.error('Please correct the validation errors before proceeding.')
    }
    topLabelRef.current.focus()
  }

  const handleEdit = (id) => {
    const newData = experienceState.find((item) => item.id === id)

    const filteredData = experienceState.filter((item) => item.id !== id)

    setShow(true)

    setJobTitle(newData.jobTitle)
    setCompanyName(newData.companyName)
    setCompanyLocation(newData.companyLocation)
    setIndustry(newData.industry)
    setWorkType(newData.workType)
    setExperienceCurrentStatus(newData.experienceCurrentStatus)
    setJobDescriptionHtml(newData.jobDescriptionHtml)
    setExperienceTo(moment(newData.experienceTo).toDate())
    setExperienceFrom(moment(newData.experienceFrom).toDate())

    setExperienceState(filteredData)
  }

  const handleDelete = (id) => {
    setExperienceState(experienceState.filter((item) => item.id !== id))
  }

  const handleGoBack = () => {
    setPage(0.4)
    setReachPage(0.4)
    localStorage.setItem('page', 0.4)
    localStorage.setItem('reachPage', 0.4)
  }

  const handleNext = () => {
    const updatePage = () => {
      localStorage.setItem('page', 0.8)
      localStorage.setItem('reachPage', 0.8)
    }
    const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))
    const oldMeta = cvData && cvData.meta ? JSON.parse(cvData.meta) : {}

    const result = validationSchema.validate(data, {
      abortEarly: false,
    })

    if (toggle || !show) {
      const meta = JSON.stringify({
        ...oldMeta,
        educationalData: educationalData,
        experienceState: [{}],
      })
      localStorage.setItem(
        'applicant-cv-data',
        JSON.stringify({
          ...cvData,
          meta: meta,
        })
      )
      setPage(0.8)
      setReachPage(0.8)
      updatePage()
      return
    }

    if (!result.error) {
      const newList = experienceState ? [...experienceState, data] : [data]
      setExperienceState(newList)
      const meta = JSON.stringify({
        ...oldMeta,
        educationalData: educationalData,
        experienceState: newList,
      })
      localStorage.setItem(
        'applicant-cv-data',
        JSON.stringify({
          ...cvData,
          meta: meta,
        })
      )
      clear()
      setPage(0.8)
      setReachPage(0.8)
      updatePage()
    } else {
      const validationErrors = result.error.details.reduce((acc, curr) => {
        acc[curr.context.key] = curr.message.replace(/"/g, '')
        return acc
      }, {})

      setFieldErrors(validationErrors)
      toast.error('Please correct the validation errors before proceeding.')
    }
  }

  const handleAddEmploymentHistory = () => {
    if (!show) {
      setShow(true)
      return
    }
    handleStoreData()
  }

  const handleRemove = () => {
    setShow(false)
  }

  return (
    <StyledPersonalInfo>
      <ScrollToTop />

      <h2 tabIndex="-1" ref={topLabelRef}>
        Employment History
      </h2>
      <p className="small-subtitle">Add your most recent job experiences.</p>

      {experienceState?.map(
        (
          {
            jobTitle,
            companyName,
            id,
            companyLocation,
            experienceFrom,
            experienceTo,
            jobDescription,
            experienceCurrentStatus,
          },
          index
        ) => (
          <ExperienceDetails
            key={index}
            jobTitle={jobTitle}
            companyName={companyName}
            companyLocation={companyLocation}
            experienceCurrentStatus={experienceCurrentStatus}
            from={experienceFrom}
            to={experienceTo}
            jobDescription={jobDescription}
            jobDescriptionHtml={jobDescriptionHtml}
            handleDelete={handleDelete}
            id={id}
            handleEdit={handleEdit}
          />
        )
      )}

      {/* toggle button */}
      {!experienceState?.length && (
        <div className="toggleBtnWrapper">
          <ToggleBtn toggle={toggle} setToggle={setToggle} />
          <p className="small-subtitle">I have no employment history</p>
        </div>
      )}

      {show && (
        <>
          {/* Field of Study */}
          <div className="inputs">
            <label htmlFor="jobTitle">Job Title</label>

            <input
              className={`input ${'jobTitle' in fieldErrors && 'has-error'}`}
              disabled={toggle}
              id="jobTitle"
              type="text"
              placeholder="Job Title"
              onChange={(e) => setJobTitle(e.target.value)}
              value={jobTitle}
            />
            {'jobTitle' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.jobTitle}</sub>
            )}
          </div>

          {/* Company Name */}
          <div className="inputs">
            <label htmlFor="companyName">Company Name</label>

            <input
              className={`input ${'companyName' in fieldErrors && 'has-error'}`}
              disabled={toggle}
              id="companyName"
              type="text"
              placeholder="Company Name"
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
            />
            {'companyName' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.companyName}</sub>
            )}
          </div>

          {/* Company Location */}
          <div className="inputs">
            <label htmlFor="companyLocation">Company Location</label>

            <input
              className={`input ${
                'companyLocation' in fieldErrors && 'has-error'
              }`}
              disabled={toggle}
              id="company-location"
              type="text"
              placeholder="Company Location"
              onChange={(e) => setCompanyLocation(e.target.value)}
              value={companyLocation.label || companyLocation}
            />
            {'companyLocation' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.companyLocation}</sub>
            )}
          </div>

          {/* Industry */}
          <div className="inputs">
            <label htmlFor="industry">Industry</label>

            <select
              style={{
                borderColor: 'industry' in fieldErrors && 'red',
              }}
              disabled={toggle}
              className="select"
              name=""
              id="industry"
              onChange={(e) => setIndustry(e.target.value)}
              value={industry}
            >
              <option>Choose Option</option>

              {industries.sort().map((industry) => (
                <option key={industry} value={industry}>
                  {industry}
                </option>
              ))}
            </select>
            {'industry' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.industry}</sub>
            )}
          </div>

          <div className="inputs">
            <label htmlFor="firstName">Job Description</label>
            <div className="moreInfo">
              <p>2-3 sentences that describe your relevant work experience</p>

              <MdLightbulbOutline
                onClick={() => setIsOpen(!isOpen)}
                className="lightIcon"
              />
            </div>

            {isOpen && <ExperiencePopup />}

            <RichTextbox
              className={'jobDescriptionHtml' in fieldErrors && 'has-error'}
              errorMessage={fieldErrors?.jobDescriptionHtml}
              content={jobDescriptionHtml}
              setContent={setJobDescriptionHtml}
              isReadOnly={toggle}
            />
          </div>

          {/* Work Type */}
          <div className="inputs rad">
            <label htmlFor="currentStatus">Work Type</label>

            <div className="radioBtns">
              <div className="radio">
                <input
                  disabled={toggle}
                  className="radio-input"
                  type="radio"
                  name="workType"
                  id="fromHome"
                  onChange={(e) => setWorkType(e.target.value)}
                  value="Permanent Work From Home"
                  checked={workType === 'Permanent Work From Home'}
                />
                <label htmlFor="fromHome" className="small-subTitle">
                  Permanent Work From Home
                </label>
              </div>
              <div className="radio">
                <input
                  className="radio-input"
                  disabled={toggle}
                  type="radio"
                  name="workType"
                  id="tempFromHome"
                  onChange={(e) => setWorkType(e.target.value)}
                  value="Temporary Work From Home"
                  checked={workType === 'Temporary Work From Home'}
                />
                <label htmlFor="tempFromHome" className="small-subTitle">
                  Temporary Work From Home
                </label>
              </div>
              <div className="radio">
                <input
                  className="radio-input"
                  disabled={toggle}
                  type="radio"
                  name="workType"
                  id="office"
                  onChange={(e) => setWorkType(e.target.value)}
                  value="Office"
                  checked={workType === 'Office'}
                />
                <label htmlFor="office" className="small-subTitle">
                  Office
                </label>
              </div>
            </div>
            {'workType' in fieldErrors && (
              <sub style={{ color: 'red' }}>{fieldErrors.workType}</sub>
            )}
          </div>

          {/* Current Status */}
          <div className="inputs rad">
            <label htmlFor="currentStatus">Current Status</label>

            <div className="radioBtns">
              <div className="radio">
                <input
                  disabled={toggle}
                  className="radio-input"
                  type="radio"
                  name="notEmployed"
                  id="currentStatus"
                  onChange={(e) => setExperienceCurrentStatus(e.target.value)}
                  value="No longer employed"
                  checked={experienceCurrentStatus === 'No longer employed'}
                />
                <label htmlFor="fromHome" className="small-subTitle">
                  No longer employed
                </label>
              </div>
              <div className="radio">
                <input
                  className="radio-input"
                  disabled={toggle}
                  type="radio"
                  name="employed"
                  id="currentStatus"
                  onChange={(e) => setExperienceCurrentStatus(e.target.value)}
                  value="Currently employed"
                  checked={experienceCurrentStatus === 'Currently employed'}
                />
                <label htmlFor="tempFromHome" className="small-subTitle">
                  Currently employed
                </label>
              </div>
            </div>
            {'experienceCurrentStatus' in fieldErrors && (
              <sub style={{ color: 'red' }}>
                {fieldErrors.experienceCurrentStatus}
              </sub>
            )}

            {/* Data Grid */}
            <div className="data-grid">
              <div className="inputs">
                <label htmlFor="">Date From</label>
                <StyledDatePickerWrapper>
                  <DatePicker
                    className={'experienceFrom' in fieldErrors && 'has-error'}
                    disabled={toggle}
                    selected={experienceFrom}
                    onChange={(date) => setExperienceFrom(date)}
                    showMonthYearPicker
                    placeholderText="MM/YYYY"
                    dateFormat="MMMM yyyy"
                  />
                  {'experienceFrom' in fieldErrors && (
                    <sub style={{ color: 'red' }}>
                      {fieldErrors.experienceFrom}
                    </sub>
                  )}
                </StyledDatePickerWrapper>
              </div>
              <div className="inputs">
                <label htmlFor="">Date To</label>
                <StyledDatePickerWrapper>
                  <DatePicker
                    className={'experienceTo' in fieldErrors && 'has-error'}
                    disabled={
                      toggle || experienceCurrentStatus === 'Currently employed'
                    }
                    selected={
                      experienceCurrentStatus === 'Currently employed'
                        ? undefined
                        : experienceTo
                    }
                    onChange={(date) => setExperienceTo(date)}
                    showMonthYearPicker
                    dateFormat="MMMM yyyy"
                    placeholderText="MM/YYYY"
                  />
                  {'experienceTo' in fieldErrors && (
                    <sub style={{ color: 'red' }}>
                      {fieldErrors.experienceTo}
                    </sub>
                  )}
                </StyledDatePickerWrapper>
              </div>
            </div>
          </div>
        </>
      )}
      {!toggle && (
        <div className="addContainer mt">
          <button onClick={handleAddEmploymentHistory} className="keywordBtn">
            <span className="mr">
              <FiPlus className="icon" />
            </span>
            Add another employment history
          </button>

          {experienceState?.length > 0 && show && (
            <button onClick={handleRemove} className="keywordBtn remove">
              Remove
            </button>
          )}
        </div>
      )}

      <Toaster position="top-right" reverseOrder={false} />

      <div className="buttonContainer">
        <button className="btn" onClick={handleGoBack}>
          Go Back
        </button>
        <button className="btn filled" onClick={handleNext}>
          Next Step
        </button>
      </div>
    </StyledPersonalInfo>
  )
}

const StyledDatePickerWrapper = styled.div`
  .has-error {
    border-color: red !important;
  }
`

export default JobExperience
